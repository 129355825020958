'use client';

import {
  DependencyKey,
  Store,
} from '@bendingspoons/web-composable-architecture';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { useCallbackRef } from 'use-callback-ref';

/**
 * Return a ref object that, every time it changes, sets itself as the provided dependency live value.
 * This is needed to correctly use ref objects as TCA dependencies, since useAsDependency cannot observe the ref.current value change.
 * This is a react reference observe limitation.
 * The underlying function uses a special ref callback.
 *
 * @example
 * export const componentRefDep: DependencyKey<() => ComponentRefApi> = {
 *   key: Symbol('componentRef'),
 *   //...
 * }
 *
 * // In any component with a suitable lifecycle and with access to the store
 * const componentRef = useRefAsDependency();
 *
 * ...
 * <SpecialComponent ref={componentRef} />
 *
 * @param store A {@link Store} with on which the dependency will be set.
 * @param key The dependency key to inject.
 */
export function useRefAsDependency<V>(
  store: Store<any, any>,
  key: DependencyKey<V>
): RefObject<V | null>;

/**
 * Return a ref object that, every time it changes, sets itself as the provided dependency live value.
 * This is needed to correctly use ref objects as TCA dependencies, since useAsDependency cannot observe the ref.current value change.
 * This is a react reference observe limitation.
 * The underlying function uses a special ref callback.
 *
 * @example
 * export const componentRefDep: DependencyKey<() => ComponentRefApiMethod> = {
 *   key: Symbol('componentRefMethod'),
 *   //...
 * }
 *
 * // In any component with a suitable lifecycle and with access to the store
 * const componentRef = useRefAsDependency(() => r => () => r.method);
 *
 * ...
 * <SpecialComponent ref={componentRef} />
 *
 * @param store A {@link Store} with on which the dependency will be set.
 * @param key The dependency key to inject.
 */
export function useRefAsDependency<V, D>(
  store: Store<any, any>,
  key: DependencyKey<D>
): RefObject<V | null> {
  const factoryRef = useRef<(values: V) => D>(undefined);
  const internalCallback = useCallback(
    (newValue: V | null, lastValue: V | null) => {
      if (newValue !== lastValue) {
        if (newValue === null) {
          store.dependencies.removeOverrides(key);
          return;
        }
        store.dependencies.set(
          key,
          factoryRef?.current
            ? factoryRef.current?.(newValue)
            : (() => newValue)()
        );
      }
    },
    [store, key]
  );
  const refWithCallback = useCallbackRef<V | null>(null, internalCallback);
  useEffect(() => () => store.dependencies.removeOverrides(key), [store, key]);
  return refWithCallback;
}
