import {
  CSRF_PROTECTION_HEADER_NAME,
  CSRF_PROTECTION_HEADER_VALUE,
  EMAIL_SESSION_HEADER,
} from '@/shared/constants';
import Cookie from 'js-cookie';

import { ApiError } from '@/api/error';
import { SECONDS_IN_MILLISECONDS } from '@/api/time';
import { getUserServiceUrl } from '@/configuration/AppContext';

const CONTENT_TYPE_HEADER_NAME = 'Content-Type';
const CONTENT_TYPE_HEADER_JSON = 'application/json';
const CONTENT_TYPE_HEADER_FORM = 'application/x-www-form-urlencoded';

type APIParams = Record<string, string | number | boolean | undefined>;

type Options = {
  method?: 'GET' | 'POST' | 'PUT';
  isFormEncoded?: boolean;
  timeout?: number;
};

const DEFAULT_OPTIONS: Options = {
  method: 'POST',
  isFormEncoded: true,
  timeout: 30 * SECONDS_IN_MILLISECONDS,
};

export async function apiRequest(
  endpoint: string,
  parameters: APIParams,
  options: Options = DEFAULT_OPTIONS,
  baseUrl: string = getUserServiceUrl()
) {
  const { isFormEncoded, method, timeout } = { ...DEFAULT_OPTIONS, ...options };

  const headers: HeadersInit = {
    [CONTENT_TYPE_HEADER_NAME]: isFormEncoded
      ? CONTENT_TYPE_HEADER_FORM
      : CONTENT_TYPE_HEADER_JSON,
    [CSRF_PROTECTION_HEADER_NAME]: CSRF_PROTECTION_HEADER_VALUE,
  };

  // TODO: make this mechanism more robust
  const emailSession = Cookie.get('evernote-email');
  if (emailSession !== undefined) {
    headers[EMAIL_SESSION_HEADER] = emailSession;
  }

  let body: string | undefined = undefined;
  if (method !== 'GET') {
    body = isFormEncoded
      ? encodeFormBody(parameters)
      : JSON.stringify(parameters);
  }

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  const signal = controller.signal;

  try {
    const response = await fetch(`${baseUrl}${endpoint}`, {
      method,
      headers,
      body,
      signal,
    });

    if (!response.ok) {
      throw new ApiError(response.status, {
        publicErrorMessage: 'API request failed',
      });
    }

    return await response.json();
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new ApiError(504, { publicErrorMessage: 'Request timed out' });
    } else {
      throw new ApiError(500, {
        publicErrorMessage: 'Unknown error',
        cause: error,
      });
    }
  } finally {
    clearTimeout(timeoutId);
  }
}

function encodeFormBody(parameters: APIParams): string {
  const bodyParams = new URLSearchParams();
  Object.entries(parameters).forEach(([parameterName, parameterValue]) => {
    switch (typeof parameterValue) {
      case 'number':
      case 'bigint':
      case 'boolean':
      case 'string':
        bodyParams.set(parameterName, parameterValue.toString());
        break;
      case 'undefined':
        break;
      case 'symbol':
      case 'object':
      case 'function':
      default:
        throw new Error(`Unsupported type for parameter ${parameterName}`);
    }
  });
  return bodyParams.toString();
}
