'use client';

import {
  AppRouterInstance,
  NavigateOptions,
  PrefetchOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

type EnhancedNavigateOption = NavigateOptions & { useWindowLocation?: boolean };

export type RouterEnhanced = Omit<AppRouterInstance, 'push' | 'replace'> & {
  push: (href: string, options?: EnhancedNavigateOption) => void;
  replace: (href: string, options?: EnhancedNavigateOption) => void;
};

/**
 * Really simple enhanced router.
 * It does nothing more than use window.location.href changes instead of router.push for not nextjs urls (i.e. /auth)
 * Push and Replace methods have also accept an extra navigation option useWindowLocation that force any url to use the default window navigation.
 */
export function useRouterEnhanced(): RouterEnhanced {
  const router = useRouter();
  const routerEnhanced = useMemo(
    () => ({
      back: router.back,
      forward: router.forward,
      refresh: router.refresh,
      push: (href: string, options?: EnhancedNavigateOption) => {
        if (
          href.startsWith('/auth') ||
          (options && options.useWindowLocation)
        ) {
          // https://developer.mozilla.org/en-US/docs/Web/API/Location/assign
          window.location.assign(href);
          return;
        }
        return router.push(href, options);
      },
      replace: (href: string, options?: EnhancedNavigateOption) => {
        if (
          href.startsWith('/auth') ||
          (options && options.useWindowLocation)
        ) {
          // https://developer.mozilla.org/en-US/docs/Web/API/Location/replace
          window.location.replace(href);
          return;
        }
        return router.push(href, options);
      },
      prefetch: (href: string, options?: PrefetchOptions) => {
        return router.prefetch(href, options);
      },
    }),
    [router]
  );
  return routerEnhanced;
}
