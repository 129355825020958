export type Options = {
  cause?: unknown;
  publicErrorMessage?: string;
  redirectUrl?: string;
};

export class ApiError extends Error {
  readonly httpStatusCode: number;
  readonly publicErrorMessage?: string;

  constructor(httpCode: number, options?: Options) {
    super(`Error with the API: http_status_code=${httpCode}`, options);

    this.httpStatusCode = httpCode;
    this.publicErrorMessage = options?.publicErrorMessage;
  }
}
