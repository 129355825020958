'use client';

import {
  DependencyKey,
  unimplemented,
} from '@bendingspoons/web-composable-architecture';

import { RouterEnhanced } from '@/utils/useRouterEnhanced';

export const appRouter: DependencyKey<RouterEnhanced> = {
  key: Symbol('appRouter'),
  get liveValue(): RouterEnhanced {
    return unimplemented('appRouter');
  },
};
