'use client';

import {
  DependencyKey,
  unimplemented,
} from '@bendingspoons/web-composable-architecture';

import { CaptchaMethods } from '@/components/core/Captcha';

export type HCaptcha = CaptchaMethods;

// NOTE: this dependency has to be initiated in the component.
// This is a required step since we get access to this dependency only through react ref set
// We are not really mocking the API but an element representation.
export const hCaptcha: DependencyKey<HCaptcha> = {
  key: Symbol('hCaptcha'),
  liveValue: unimplemented('hCaptcha'),
};
