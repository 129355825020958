'use client';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

export type CaptchaMethods = {
  executeCaptcha: () => Promise<string | null>;
  resetCaptcha: () => void;
};

type CaptchaProps = {
  setToken: (token: string | null) => void;
  mode: 'invisible' | 'visible';
};

const Captcha = forwardRef<CaptchaMethods, CaptchaProps>(
  ({ setToken, mode }, ref) => {
    const captchaRef = useRef<HCaptcha>(null);
    const invalidateTokenRef = useRef(() => setToken(null)); // trick to use function in useEffect hooks without forcing their unwanted re-execution

    const visibleSiteKey = process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY_VISIBLE!;
    const invisibleSiteKey =
      process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY_INVISIBLE!;

    useEffect(() => {
      invalidateTokenRef.current = () => setToken(null);
    }, [setToken]);

    useImperativeHandle(
      ref,
      () => ({
        executeCaptcha: async () => {
          try {
            if (!captchaRef.current) {
              throw new Error('Invalid ref');
            }
            const prevRes = captchaRef.current.getResponse();
            if (prevRes) {
              return prevRes;
            } // captcha already resolved -> use the resolved value
            const { response } = await captchaRef.current.execute({
              async: true,
            });
            return response;
          } catch (error) {
            // _TODO: understand if we want to propagate errors to specific handle error codes https://docs.hcaptcha.com/configuration/#error-codes
            invalidateTokenRef.current();
            console.error('Error executing captcha:', error);
            return null;
          }
        },

        resetCaptcha: () => {
          try {
            captchaRef.current?.resetCaptcha();
          } catch (error) {
            console.error('Error resetting captcha:', error);
          }
        },
      }),
      []
    ); // important to not re-execute it at every component rerender

    useEffect(() => {
      invalidateTokenRef.current();
    }, [mode]); // having mode here is desired. We want to trigger reset every time the component is unmounted (AKA changes mode)

    // having 2 equal wrapper div is a desired behavior.
    // HCaptcha uses them to mount the challenge, having them conditionally rendered results into having a different DOM ref every time we switch mode
    return mode === 'visible' ? (
      <div className="flex items-center justify-center">
        <HCaptcha
          sitekey={visibleSiteKey}
          onVerify={setToken}
          onError={invalidateTokenRef.current}
          onExpire={invalidateTokenRef.current}
          ref={captchaRef}
        />
      </div>
    ) : (
      <div className="flex items-center justify-center">
        <HCaptcha
          sitekey={invisibleSiteKey}
          onVerify={setToken}
          onError={invalidateTokenRef.current}
          onExpire={invalidateTokenRef.current}
          size="invisible"
          ref={captchaRef}
        />
      </div>
    );
  }
);

Captcha.displayName = 'Captcha';

export default Captcha;
