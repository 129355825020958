'use client';

import {
  DependencyKey,
  unimplemented,
} from '@bendingspoons/web-composable-architecture';

import { AppContextType } from '@/configuration/types';

export type AppContextEmail = Pick<AppContextType, 'email' | 'setEmail'>;

// NOTE: this dependency has to be initialized through useAsDependency in the component.
// This dependency proxies the AppContext email operations
export const appContextEmail: DependencyKey<AppContextEmail> = {
  key: Symbol('appContextEmail'),
  liveValue: unimplemented('appContextEmail'),
};
