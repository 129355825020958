import React from 'react';

type ErrorMessageProps = {
  message: string | React.ReactNode;
  textAlignment?: 'left' | 'center' | 'right';
  marginOverride?: string;
};

const ErrorMessage = ({
  message,
  textAlignment,
  marginOverride,
}: ErrorMessageProps) => {
  const alignmentClass = (() => {
    switch (textAlignment) {
      case 'left':
        return 'text-left';
      case 'right':
        return 'text-right';
      case 'center':
      case undefined:
      default:
        return 'text-center';
    }
  })();

  return (
    <span
      className={`block w-full text-r14 text-secondary-red-400 ${
        marginOverride ?? 'my-4'
      } ${alignmentClass}`}
    >
      {message}
    </span>
  );
};

export default ErrorMessage;
