import React from 'react';

interface LoadingWrapperProps {
  isLoading: boolean;
  children: React.ReactNode;
  className?: string;
}

const LoadingWrapper = ({
  isLoading,
  children,
  className,
}: LoadingWrapperProps) => {
  return (
    <div
      className={
        `${
          isLoading ? 'pointer-events-none animate-custom-pulse' : ''
        } relative z-20 flex flex-col space-y-4 pt-8 md:pt-10 ` + className
      }
    >
      {children}
    </div>
  );
};

export default LoadingWrapper;
